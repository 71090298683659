import React from "react"
import PropTypes from "prop-types"

import "./image-with-text.sass"
import Img from "gatsby-image"
import Link from "gatsby-link"
import classNames from 'classnames'

const ImageWithText = ({ title, body, imageFluid, imageAlt, imageSide, linkPath, linkText, textLength }) => (
  <section className={"_13"}>
      <div className={classNames("_7q", { "_80": imageSide === 'left' }, { "_7y": textLength === 'long' }, { "_7x": textLength === 'short' })}>

        <div className="_7r">
        <div className="_7s">

          <h3 className="_4e _7t">{title}</h3>
          <p className="_ap">{body()}</p>
          {linkPath && linkText &&
          <div className="_7u">
            <Link className={"_4t"} to={linkPath}>{linkText}</Link>
          </div>
          }
          </div>
        </div>
        <div className="_7v">
          <Img imgStyle={{ "objectFit": "cover" }} className={"_7w"}
               fluid={imageFluid} alt={imageAlt}></Img>
        </div>
      </div>
  </section>
)

ImageWithText.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.func.isRequired,
  imageFluid: PropTypes.object.isRequired,
  imageAlt: PropTypes.string.isRequired,
  linkPath: PropTypes.string,
  linkText: PropTypes.string,
  imageSide: PropTypes.oneOf(["left", "right"]),
  textLength: PropTypes.oneOf(["long", "short"]),
}

ImageWithText.defaultProps = {
  imageSide: "right",
  textLength: "short",
}

export default ImageWithText

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import SolidHero from "../components/solid-hero/solid-hero"
import ContactAndAddressTile from "../components/contact-and-address-tile/contact-and-address-tile"
import React from "react"
import ImageWithText from "../components/image-with-text/image-with-text"

const FahrschulWechselPage = ({ data }) => (
  <Layout>
    <SEO title="Fahrschul-Wechsel"
         description={"Deine aktuelle Fahrschule bremst dich aus? Wir machen dir den Wechsel ganz entspannt und einfach – jetzt direkt unverbindlich bei der Fahrschule Rauscher anfragen."}/>
    <SolidHero primary title={"Fahrschul-Wechsel"}
               subtitle={() => <>Einfach anmelden, wir übernehmen den Rest.</>}></SolidHero>

    <ImageWithText textLength={'long'} title={"Wir kümmern uns um deinen Fahrschul-Wechsel"} linkPath={"/anmeldung/"}
                   linkText={"Melde dich jetzt an"}
                   body={() => <>Manchmal reiht sich Fahrt an Fahrt und trotzdem kommt man gefühlt keinen Meter voran.
                     Insbesondere menschlich sollte man sich
                     verstehen, schließlich ist der eigene Führerschein ein großer Schritt in Richtung Unabhängigkeit.
                     Und da darf auch der Spaß am Fahren nicht zu kurz kommen.<br/><br/>In anderen
                     Fällen ist mit der Fahrschule eigentlich alles Paletti – aber dann crasht ein plötzlicher Umzug
                     das Projekt „eigener Führerschein“.<br/><br/>Warum auch immer du wechseln willst: Es geht. Und
                     ist gar nicht so kompliziert, wie du vielleicht denkst. Melde dich einfach bei uns an und wir
                     erledigen die Ummeldung beim TÜV für dich.<br/><br/>Alle Theorie- und Praxisstunden, die du in den
                     letzten zwei Jahren bei deiner vorherigen Fahrschule hattest, können mitgenommen werden und gehen
                     somit nicht verloren.</>}
                   imageAlt={"Wechsle jetzt zur Fahrschule Rauscher und wir übernehmen den Rest."}
                   imageFluid={data.file.childImageSharp.fluid}></ImageWithText>
    <ContactAndAddressTile></ContactAndAddressTile>
  </Layout>
)

export default FahrschulWechselPage
export const query = graphql`
  query FahrschulWechselPageQuery {
    file(relativePath: { eq: "fahrschul-wechsel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
